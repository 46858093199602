import './App.css';
import Gradient from "javascript-color-gradient";
import { reverse, groupBy, round, sortBy, endsWith } from "lodash";
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel"
import Switch from "@material-ui/core/Switch";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const colorGradient = new Gradient();

colorGradient.setGradient("#30d144", "#faf6f6", "#f94949",);
colorGradient.setMidpoint(10);

const sum = (a, b) => a + b;
const average = arr => arr.reduce(sum) / arr.length;

const million = 1000000;
const thousand = 1000;

export function Benchmarks({ allRows }) {
  let latestMaster = sortBy(allRows.filter(x => x[0].branch === "master"), "date")[0];
  let [selectedRow, setSelectedRow] = useState(latestMaster);
  const [homogenousUnits, setHomogeneousUnits] = useState(false);

  const Row = ({ rows }) => {
    // The header row has the things in common.
    let common_row = rows[0];
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    // We want to map the range 50%..200% onto the range 0..10
    // It's been a long time since algebra, so the best way I
    // could come up with to do this in an even way was using log:
    // f(x) = 5 * (log x/ log 2) + 5, where x = the ration of the
    // two scores.
    let toColor = (x) => {
      let f_of_x = 5 * (Math.log(x) / Math.log(2)) + 5;
      let color_index = (() => {
        if (f_of_x < 0) {
          return 1
        } else if (f_of_x > 9) {
          return 9
        } else {
          return Math.round(f_of_x);
        }
      })();
      return colorGradient.getColor(color_index);
    };
    let rowScore = average(selectedRow.map(({name, time}) => (rows.find(x => x.name === name).time) / time));
    let rowSum = rows.map(x => x.time).reduce(sum);
    let backgroundColor = toColor(rowScore);

    let format = (unit, value) => {
      let [v, u] = (() => {
        switch (unit) {
          case "s":
            if (value % million < value && !homogenousUnits) {
              return [(value / million), "ms"];
            } else if (value % thousand > 0 && !homogenousUnits) {
              return [value / thousand, "us"]
            } else {
              return [value, "ns"]
            }
          case "w":
            if (value % thousand < value && !homogenousUnits) {
              return [value / 1000, "kw"]
            } else {
              return [value, "w"]
            }
          default:
            throw new Error("missing pattern");
        }
      })();
      return `${round(v, 3)} ${u}`
    };
    let Cell = ({ propName, row, unit }) => {
      let selected = selectedRow.find(x => x.name === row.name);
      let backgroundColor = toColor(row[propName] / selected[propName]);
      return <TableCell align="right" style={{ backgroundColor }}>
        {format(unit, row[propName])}
      </TableCell>
    }

    return (
      <React.Fragment>
        <TableRow className={classes.root} style={{ backgroundColor }}>
          <TableCell>
            <Tooltip title="Expand row">
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Select for comparison">
              <Checkbox checked={common_row.sha == selectedRow[0].sha} onClick={() => setSelectedRow(rows)}></Checkbox>
            </Tooltip>
          </TableCell>
          <TableCell align="left">{new Date(common_row.date * 1000).toLocaleString()}</TableCell>
          <TableCell component="th" scope="row">
            {common_row.branch}
          </TableCell>
          <TableCell align="right">{common_row.sha}</TableCell>
          <TableCell align="right" style={{ backgroundColor: backgroundColor }}>{format("s", rowSum)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Tests
              </Typography>
                <Table size="small" aria-label="tests">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Name</TableCell>
                      <TableCell align="right">Time/Run</TableCell>
                      <TableCell align="right">mWd/Run</TableCell>
                      <TableCell align="right">mjWd/Run</TableCell>
                      <TableCell align="right">Prom/Run</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      return (
                        <TableRow key={row.name}>
                          <TableCell align="right">{row.name}</TableCell>
                          <Cell row={row} propName="time" unit="s" />
                          <Cell row={row} propName="mwd" unit="w" />
                          <Cell row={row} propName="mjwd" unit="w" />
                          <Cell row={row} propName="prom" unit="w" />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto" }} component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormLabel  >
                Show Homogenous Units
                <Switch checked={homogenousUnits} onChange={() => setHomogeneousUnits(!homogenousUnits)} />
              </FormLabel>
            </TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell align="right">Commit</TableCell>
            <TableCell align="right">Total Run Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allRows.map((rows) => (
            <Row key={rows[0].sha} rows={rows} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function App() {
  const [allRows, setAllRows] = useState([]);
  useEffect(async () => {
    let data = await fetch("https://tezos-benchmark.surge.sh/data.csv");
    data = await data.text();
    data = data.trim().split("\n");

    // Parse all durations to ns and all words to w
    let parse = x => {
      let xs = x.replaceAll("_", "");
      switch (true) {
        case endsWith(xs, "ms"):
          return Number.parseFloat(xs.slice(0, -2)) * million
        case endsWith(xs, "us"):
          return Number.parseFloat(xs.slice(0, -2)) * thousand
        case endsWith(xs, "ns"):
          return Number.parseFloat(xs.slice(0, -2))
        // Order is important here - kw must come before w
        case endsWith(xs, "kw"):
          return Number.parseFloat(xs.slice(0, -2)) * thousand
        case endsWith(xs, "w"):
          return Number.parseFloat(xs.slice(0, -1))
        default:
          throw new Error("Missing case", xs);
      }
    }
    let allRows = data.map(x => {
      let data = x.split(",");
      return {
        date: Number(data[0]),
        branch: data[1],
        sha: data[2],
        name: data[3],
        time: parse(data[4]),
        mwd: parse(data[5]),
        mjwd: parse(data[6]),
        prom: parse(data[7]),
      }
    });
    allRows = Object.values(groupBy(allRows, ({ branch, sha }) => branch + sha));
    allRows = sortBy(allRows, ({date}) => date);
    setAllRows(allRows);
  }, []);

  if (!allRows.length) {
    return <p>Loading...</p>;
  }

  return <Benchmarks allRows={allRows} />;
}